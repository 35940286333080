import Box from "./Box";
import {useEffect, useRef, useState, useLayoutEffect} from "react";
import "./Transcription.scss";
import {ASR_URL} from "../../utils/variables";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrophone, faPause} from "@fortawesome/free-solid-svg-icons";
import {translateAsync} from "../../utils/functions";

const Transcription = () => {
    const {t} = useTranslation()
    const [firstStart, setFirstStart] = useState(true)
    const [isPlaying, setPlaying] = useState(false)
    const [stream, setStream] = useState(null)
    const [isFinished, setIsFinished] = useState(true)

    const recordBtn = useRef()
    const stopBtn = useRef()

    const socket = useRef()
    const audioCtx = useRef();
    const mediaStream = useRef();
    const recorder = useRef()   // the microphone

    const [inputSentences, setInputSentences] = useState([])
    const [outputSentences, setOutputSentences] = useState([])

    var textToTranslate = useRef("");
    var translatableSentences = useRef([]);
    var currentSentence = useRef(null);

    useEffect(() => {
        if (socket.current) {
            // Connection opened
            socket.current.addEventListener('open', function () {
                console.log('socket connected');
            });

            // Connection closed
            socket.current.addEventListener('close', function () {
                console.log('socket disconnected');

                socket.current.close()
                socket.current.removeEventListener("open", () => {})
                socket.current.removeEventListener("close", () => {})
                socket.current.removeEventListener("message", () => {})
                socket.current = null;
                setIsFinished(true)
            });

            // Listen for messages
            socket.current.addEventListener('message', function (event) {
                console.log(event?.data)
                // Remove quotes and end-of-line characters
                const newText = event?.data.replace(/['"]+/g, '');
                const cleanText = newText.replace(/\\n/g, '').replace("- ", '');

                if (cleanText === ".") {
                    return;
                }

                setInputSentences(prevMessages => {
                    if (newText === "- ") {
                        if (textToTranslate.current !== "") {
                            translatableSentences.current = [...translatableSentences.current, textToTranslate.current];
                            textToTranslate.current = "";
                        }
                        return prevMessages;
                    }
                    else {
                        textToTranslate.current += cleanText;
                        if (newText.endsWith("\\n") || newText.endsWith("- ") || cleanText.endsWith(".")) {
                            translatableSentences.current = [...translatableSentences.current, textToTranslate.current];
                            textToTranslate.current = "";
                        }

                        if (cleanText.startsWith(' ') && prevMessages.length !== 0 && !prevMessages[prevMessages.length - 1].endsWith(".")) {
                            return [...prevMessages.slice(0, -1), prevMessages[prevMessages.length - 1].concat(cleanText)];
                        }
                        else {
                            return [...prevMessages, cleanText];
                        }
                    }
                })
            })
        }
    }, [socket.current])

    useEffect(() => {
        if (translatableSentences.current.length !== 0 && currentSentence.current == null) {
            currentSentence.current = translatableSentences.current.shift()
            translateAsync(currentSentence.current)
                .then(data => {
                    console.log("Got result ", data?.result)
                    setOutputSentences(prevMessages => [...prevMessages, data?.result])
                    currentSentence.current = null;
                })
                .catch(err => {
                    if (err.name === "AbortError") {
                        return;
                    }
                    console.error("Error from returning from server:", err)
                })
        }
    }, [translatableSentences.current])

    useEffect(() => {
        const asrBox = document.getElementById("asr");
        if (asrBox) {
            asrBox.scrollTop = asrBox.scrollHeight;
        }
    }, [inputSentences])

    useEffect(() => {
        const translationBox = document.getElementById("translation");
        if (translationBox) {
            translationBox.scrollTop = translationBox.scrollHeight;
        }
    }, [outputSentences])

    const initWebSocket = async () => {
        if (!socket.current) {
            socket.current = new WebSocket(ASR_URL)
        }
    }

    // get the user's media stream
    const startStream = async () => {

        if (!audioCtx.current) {
            //audioCtx.current = new AudioContext({ sampleRate: 44100 });
            audioCtx.current = new AudioContext();
        }

        const newStream = await navigator.mediaDevices.getUserMedia({audio: true})

        // creates an audio node from the microphone incoming stream
        mediaStream.current = audioCtx.current.createMediaStreamSource(newStream);

        // Loading the worklet processor
        await audioCtx.current.audioWorklet.addModule("/recorder.worklet.js")

        // Create the recorder worklet
        recorder.current = new AudioWorkletNode(audioCtx.current, "recorder.worklet")

        await initWebSocket()

        mediaStream.current.connect(recorder.current).connect(audioCtx.current.destination)
        console.log("start Recorder worklet")

        setStream(newStream)
        setPlaying(true)
        setFirstStart(false)

        recorder.current.port.onmessage = (e) => {
            let samples = e.data;

            let buf = new Int16Array(samples.length);
            for (let i = 0; i < samples.length; ++i) {
                let s = samples[i];
                if (s >= 1)
                    s = 1;
                else if (s <= -1)
                    s = -1;

                samples[i] = s;
                buf[i] = s * 32767;
            }

            if (socket.current && socket.current.readyState === WebSocket.OPEN) {
                socket.current.send(samples)
            }
        }
    };

    // stops the user's media stream
    const stopStream = () => {
        console.log('recorder stopped');

        socket.current.send('Done');
        console.log('Sent Done');

        recorder.current.disconnect(audioCtx.current.destination);
        mediaStream.current.disconnect(recorder.current);
        audioCtx.current.close()

        stream.getTracks().forEach((track) => track.stop());

        // Set the audio context to null to make a new one every time user starts recording
        audioCtx.current = null;

        setPlaying(false);
        setIsFinished(false)

    };

    let section;
    if (firstStart) {
        section =
            <>
                <p className={"transcription__text"}>{t("Press Start and start speaking")}</p>
                <button className={"transcription__button transcription__button--firststart"} ref={recordBtn} onClick={startStream}>
                    <FontAwesomeIcon icon={faMicrophone} className={"transcription__microphone"} />
                    {t("Start")}
                </button>
            </>
    }
    else if (isPlaying) {
        section =
            <>
                <Box inputBox={true} sentences={inputSentences} />
                <button className={"transcription__button transcription__button--recording"} ref={stopBtn} onClick={stopStream}>
                    <span className="fa-layers fa-fw fa-1x">
                        <FontAwesomeIcon icon={faPause} />
                    </span>
                </button>
                <Box outputBox={true} sentences={outputSentences} />
            </>
    }
    else {
        section =
            <>
                <Box inputBox={true} sentences={inputSentences} />
                <button className={"transcription__button"} ref={recordBtn} onClick={startStream} disabled={!isFinished}>
                    <span className="fa-layers fa-1x">
                        <FontAwesomeIcon icon={faMicrophone} />
                    </span>
                </button>
                <Box outputBox={true} sentences={outputSentences} />
            </>
    }

    return (
        <section className={`transcription ${firstStart ? "transcription--first" : ""}`}>
            {section}

            {/*
            <button className="btn btn-primary btn-block" id="clear">Puhasta</button>
            <div className="mb-3">
                <label htmlFor="translations" className="form-label">Results</label>
                <textarea className="form-control" id="translations" rows="8"></textarea>
            </div>*/}
        </section>
    )
}

export default Transcription